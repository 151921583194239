import React, { useEffect, useRef, useContext } from 'react';
import { graphql } from 'gatsby';
import { useSprings, animated, useSpring, interpolate } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { parsePath } from 'gatsby-link';
import clamp from 'lodash-es/clamp'; // usage: clamp(value, min, max)
import calculateDimensions, { PADDING } from '../utils/calculateDimensions';
import debounce from '../utils/debounce';
import { AppContext } from '../AppContext';
import { LocaleContext } from '../components/Layout';
import Div100vh from 'react-div-100vh';
import HomePreview from '../components/HomePreview';
import SEO from '../components/SEO';
import Arrow from '../components/Atoms/Arrow';
import { useTriggerTransition } from '@mjso/gatsby-plugin-transition-link';
import './section.scss';
import Spanify from '../components/Spanify';
import DragHint from '../components/DragHint/DragHint';

const AnimatedArrow = animated(Arrow);

const Section = ({ data, entry, location }) => {
  const galleries = data.galleries.edges;
  const gatsbyLoader =
    typeof window === 'undefined'
      ? {
          hovering: () => {},
          prefetch: () => {},
        }
      : window.___loader;

  const triggerTransition = useTriggerTransition();

  const { setTheme } = useContext(AppContext);
  const { locale } = useContext(LocaleContext);

  // Holds the current active gallery index
  const index = useRef(
    // entry &&
    typeof entry.state.active !== 'undefined' &&
      galleries.findIndex(x => x.node.slugs[0] === entry.state.active) !== -1
      ? galleries.findIndex(x => x.node.slugs[0] === entry.state.active)
      : 0
  );

  const sizes = useRef(
    calculateDimensions(
      galleries[index.current].node.data.body[0].items[0].image.localFile
        .childImageSharp.fixed.aspectRatio
    )
  );

  const deltaX = useRef(
    Math.abs(sizes.current.windowWidth - sizes.current.containerWidth)
  );
  const deltaY = useRef(
    Math.abs(sizes.current.windowHeight - sizes.current.containerHeight)
  );

  const setSizes = () => {
    sizes.current = calculateDimensions(
      galleries[index.current].node.data.body[0].items[0].image.localFile
        .childImageSharp.fixed.aspectRatio
    );
    deltaX.current = Math.abs(
      sizes.current.windowWidth - sizes.current.containerWidth
    );
    deltaY.current = Math.abs(
      sizes.current.windowHeight - sizes.current.containerHeight
    );
  };

  const setMargins = (i, current) => {
    if (sizes.current.windowWidth <= 576) {
      if (i === Math.round(current)) return 4;
      else return 0;
    } else if (sizes.current.windowWidth <= 768) {
      if (i === Math.round(current)) return 8;
      else return 0;
    } else if (sizes.current.windowWidth <= 1200) {
      if (i === Math.round(current)) return 12;
      else return 0;
    } else {
      if (i === Math.round(current)) return 16;
      else return 0;
    }
  };

  const setTop = current => {
    if (sizes.current.windowWidth <= 576) {
      return -Math.round(current) * 32 + 4 - 2;
    } else if (sizes.current.windowWidth <= 768) {
      return -Math.round(current) * 40 + 8 - 2;
    } else if (sizes.current.windowWidth <= 1200) {
      return -Math.round(current) * 44 + 12 - 2;
    } else {
      return -Math.round(current) * 52 + 16 - 2;
    }
  };

  useEffect(() => {
    setTheme('light');
    const debouncedHandleResize = debounce(() => {
      setSizes();
      setY(() => {
        let y = 0;
        let offset = (index.current + 1) * -window.innerHeight;
        let mountHome = 0;

        return { y, offset, mountHome, onRest: onDragYRest };
      });
      setYSlides(i => {
        const baseY = (i - index.current) * window.innerHeight;

        return {
          slideY: baseY,
          display: 'flex',
          current: index.current,
        };
      });
    }, 250);

    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  // Holds our container
  const containerRef = useRef();

  // Holds the active gallery index where the drag movement started
  const startingIndex = useRef(0);

  // Holds the active gallery slug
  const activeGallery = useRef(
    // entry &&
    typeof entry.state.active !== 'undefined' &&
      galleries.findIndex(x => x.node.slugs[0] === entry.state.active) !== -1
      ? entry.state.active
      : typeof galleries[index.current] !== 'undefined'
      ? galleries[index.current].node.slugs[0]
      : ''
  );

  // This value holds 'y' or 'x' and tells us if the user is trying
  // to drag horizontally or vertically
  const axis = useRef(null);

  const onDragXRest = ({ mountGallery }) => {
    if (mountGallery) {
      triggerTransition({
        to: `${location.pathname}/${activeGallery.current}`,
        exit: {
          length: 0.1,
        },
        entry: {
          length: 0,
          state: { from: location.pathname.replace('/', '') },
        },
      });
    }
  };

  const onDragYRest = ({ mountHome }) => {
    if (mountHome) {
      triggerTransition({
        to: locale === 'en' ? '/' : `/${locale}`,
        exit: {
          length: 0.1,
        },
        entry: {
          length: 0,
          state: {
            from:
              locale === 'en'
                ? location.pathname.split('/')[1]
                : location.pathname.split('/')[2],
          },
        },
      });
    } else {
      // preload current gallery page-data.json
      const to = `${location.pathname.replace(/\/$/, '')}/${
        galleries[index.current].node.slugs[0]
      }`;
      gatsbyLoader.hovering(parsePath(to).pathname);
    }
  };

  // Takes care of the x movement (width and height of the img)
  const [{ w, h, mountGallery }, setX] = useSpring(() => ({
    w: 0,
    h: 0,
    mountGallery: 0,
    onRest: onDragXRest,
    config: {
      clamp: true,
      precision: 1,
      tension: 210,
      friction: 57,
      mass: 6,
      velocity: 3,
    },
  }));

  const [{ y, offset, mountHome }, setY] = useSpring(() => ({
    y: 0,
    mountHome: 0,
    offset: typeof window !== 'undefined' ? -window.innerHeight : -2000,
    onRest: onDragYRest,
    config: {
      clamp: true,
      precision: 1,
    },
  }));

  // Spring stuff - takes care of the y movement for the slides
  const [slides, setYSlides] = useSprings(galleries.length, i => ({
    slideY:
      (i - index.current) *
      (typeof window !== 'undefined' ? window.innerHeight : 2000),
    display: i < index.current - 2 || i > index.current + 2 ? 'none' : 'flex',
    current: index.current,
  }));

  // Drag stuff
  const bind = useDrag(
    ({
      last,
      down,
      movement: [mx, my],
      direction: [xDir, yDir],
      distance,
      cancel,
      canceled,
      first,
    }) => {
      setTheme('light');
      // Set the current axis to prevent x behaviours when draggin vertically and viceversa
      if (!axis.current) {
        if (Math.abs(xDir) > Math.abs(yDir)) axis.current = 'x';
        else if (Math.abs(yDir) > Math.abs(xDir)) axis.current = 'y';
      }

      // Show drag/dragging cursor (we have to do this outside of the render cycle)
      if (down) containerRef.current.classList.add('is-dragging');
      else containerRef.current.classList.remove('is-dragging');

      // Save the slide index the drag started at
      if (first && down) {
        startingIndex.current = index.current;
      }

      if (axis.current === 'y') {
        // If I've dragged for more than 1/3 of the screen height snap to the next slide
        if (down && distance > window.innerHeight / 4) {
          cancel(
            (index.current = clamp(
              index.current + (yDir > 0 ? -1 : 1),
              0,
              galleries.length - 1
            ))
          );
        }
        if (canceled) {
          activeGallery.current = galleries[index.current].node.slugs[0];
          setSizes();
        }
      }

      setX(() => {
        let w =
          down && axis.current === 'x'
            ? (mx / window.innerWidth) * deltaX.current
            : 0;
        let h =
          down && axis.current === 'x'
            ? (mx / window.innerHeight) * deltaY.current
            : 0;
        let mountGallery = 0;

        // If I've dragged for more than 1/3 of the screen width
        if (axis.current === 'x') {
          if (down && distance > window.innerWidth / 5 && xDir < 0) {
            // Cancel the drag
            cancel();
          }
          if (canceled) {
            w = -deltaX.current;
            h = -deltaY.current;
            mountGallery = 1;
            setTheme('dark');
          }
        }
        return { w, h, mountGallery, onRest: onDragXRest };
      });

      setY(() => {
        let y = down && axis.current === 'y' ? my : 0;
        let offset = (index.current + 1) * -window.innerHeight;
        let mountHome = 0;

        // If the drag started at the top slide, it's been cancelled, and we are dragging down:
        if (
          startingIndex.current === 0 &&
          canceled &&
          yDir > 0 &&
          axis.current === 'y'
        ) {
          y = window.innerHeight;
          mountHome = 1;
        }

        return { y, offset, mountHome, onRest: onDragYRest };
      });

      setYSlides(i => {
        // Only display current, the previous 2 and the next 2 slides
        // if (i < index.current - 2 || i > index.current + 2)
        //   return { display: 'none', current: index.current };

        const baseY = (i - index.current) * window.innerHeight;

        // Don't slide down if we are the the first slide
        const y =
          down && axis.current === 'y' && !(index.current === 0 && my > 0)
            ? my
            : 0;

        return {
          slideY: baseY + y,
          display: 'flex',
          current: index.current,
        };
      });

      // Reset axis
      if (last) axis.current = null;
    }
  );

  return (
    <>
      <SEO
        title={
          location.pathname.includes('reportage') ? 'Reportage' : 'Corporate'
        }
        image={
          location.pathname.includes('reportage')
            ? data.reportage.data.image.url
            : data.corporate.data.image.url
        }
        lang={locale}
      />
      <DragHint />
      <Div100vh>
        <HomePreview
          reportage={data.reportage.data.image.url}
          corporate={data.corporate.data.image.url}
          y={y}
          offset={offset}
          active={
            locale === 'en'
              ? location.pathname.split('/')[1]
              : location.pathname.split('/')[2]
          }
        />
        <div className="galleries-index" ref={containerRef}>
          <animated.div
            className="arrow-container"
            {...bind()}
            style={{
              transform: w.interpolate(
                w => `translate3d(${w < 0 ? w : 0}px, 0px, 0px)`
              ),
              opacity: w.interpolate(
                w =>
                  `${w < 0 ? 1 - clamp(Math.abs(w) / deltaX.current, 0, 1) : 1}`
              ),
            }}
          >
            <div className="arrow">
              <Arrow
                right
                onClick={() => {
                  setTheme('dark');
                  setX(() => ({
                    w: -deltaX.current,
                    h: -deltaY.current,
                    mountGallery: 1,
                    onRest: onDragXRest,
                  }));
                }}
              />
            </div>
          </animated.div>
          {slides.map(({ slideY, display }, i) => (
            <animated.div
              className="galleries-index-gallery"
              {...bind()}
              key={i}
              style={{
                display,
                transform: slideY.interpolate(
                  slideY => `translate3d(0, ${slideY}px, 0px)`
                ),
              }}
            >
              <animated.img
                className="gallery-preview-img"
                style={{
                  transform: interpolate(
                    [
                      slideY.interpolate(slideY => slideY),
                      y.interpolate(y => y),
                    ],
                    (slideY, y) =>
                      `translate3d(0px, ${-slideY / 2}px, 0px) scale(${
                        startingIndex.current === 0
                          ? 1 +
                            clamp(y / sizes.current.windowHeight, 0, 1) * 0.2
                          : 1
                      })`
                  ),
                  width: w.interpolate(w =>
                    w < 0
                      ? `${sizes.current.windowWidth - Math.abs(w)}px`
                      : '100%'
                  ),
                  height: h.interpolate(h =>
                    h < 0
                      ? `${sizes.current.windowHeight - Math.abs(h)}px`
                      : '100%'
                  ),
                  marginTop: h.interpolate(
                    h => `${clamp(Math.abs(h), 0, PADDING.TOP)}px`
                  ),
                  marginBottom: h.interpolate(
                    h => `${clamp(Math.abs(h), 0, PADDING.BOTTOM)}px`
                  ),
                }}
                src={galleries[i].node.data.body[0].items[0].image.url}
              />
              <animated.div
                className="dark-overlay"
                style={{
                  backgroundColor: w.interpolate(
                    w =>
                      `rgba(0, 0, 0, ${
                        w < 0
                          ? 0.4 - clamp(Math.abs(w) / deltaX.current, 0, 0.4)
                          : 0.4
                      })`
                  ),
                }}
              />
            </animated.div>
          ))}
          <animated.div
            className="galleries-index-links-wrapper"
            {...bind()}
            style={{
              transform: w.interpolate(
                w => `translate3d(${w < 0 ? w : 0}px, 0px, 0px)`
              ),
              opacity: w.interpolate(
                w =>
                  `${w < 0 ? 1 - clamp(Math.abs(w) / deltaX.current, 0, 1) : 1}`
              ),
            }}
          >
            <div className="galleries-index-links">
              {slides.map(({ current }, i) => {
                const to = `${location.pathname.replace(/\/$/, '')}/${
                  galleries[i].node.slugs[0]
                }`;
                gatsbyLoader.prefetch(parsePath(to).pathname);
                const text =
                  locale === 'en'
                    ? galleries[i].node.data.title.text
                    : galleries[i].node.data.title_es.text;
                let classNames = 'text-length-5';
                if (text.length > 5 && text.length <= 10) {
                  classNames = 'text-length-10';
                } else if (text.length > 10 && text.length <= 15) {
                  classNames = 'text-length-15';
                } else if (text.length > 15 && text.length <= 20) {
                  classNames = 'text-length-20';
                } else if (text.length > 20) {
                  classNames = 'text-length-25';
                }
                return (
                  <div key={i} className="title-wrapper">
                    <animated.h2
                      {...bind()}
                      data-c={current}
                      data-i={i}
                      className={classNames}
                      style={{
                        transform: current.interpolate(
                          current =>
                            `scale(${i === Math.round(current) ? 1.6 : 1})`
                        ),
                        marginBottom: current.interpolate(current =>
                          setMargins(i, current)
                        ),
                        marginTop: current.interpolate(current =>
                          setMargins(i, current)
                        ),
                        top: current.interpolate(current => setTop(current)),
                        display: current.interpolate(current =>
                          current === -1 ? 'none' : 'inline-block'
                        ),
                        width: current.interpolate(current =>
                          typeof window !== 'undefined' &&
                          window.innerWidth <= 768 &&
                          i === Math.round(current)
                            ? '53vw'
                            : 'auto'
                        ),
                      }}
                      onClick={() => {
                        if (i === current.value) {
                          setTheme('dark');
                          setX(() => ({
                            w: -deltaX.current,
                            h: -deltaY.current,
                            mountGallery: 1,
                            onRest: onDragXRest,
                          }));
                          return;
                        }
                        index.current = i;
                        activeGallery.current =
                          galleries[index.current].node.slugs[0];
                        setSizes();
                        setYSlides(i => ({
                          slideY: (i - index.current) * window.innerHeight,
                          display: 'flex',
                          current: index.current,
                        }));
                      }}
                    >
                      <Spanify text={text} />
                    </animated.h2>
                  </div>
                );
              })}
            </div>
          </animated.div>
        </div>
      </Div100vh>
    </>
  );
};

export default Section;

export const ReportageQuery = graphql`
  query Galleries($slug: String) {
    galleries: allPrismicGallery(
      sort: { fields: [data___order, id], order: [ASC, ASC] }
      filter: { data: { section: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            title_es {
              text
            }
            body {
              items {
                image {
                  url
                  localFile {
                    childImageSharp {
                      fixed {
                        aspectRatio
                      }
                    }
                  }
                }
              }
            }
            section {
              slug
            }
          }
          slugs
        }
      }
    }
    reportage: prismicSection(slugs: { eq: "reportage" }) {
      slugs
      data {
        title {
          text
        }
        image {
          url
        }
      }
    }
    corporate: prismicSection(slugs: { eq: "corporate" }) {
      slugs
      data {
        title {
          text
        }
        image {
          url
        }
      }
    }
  }
`;
