import React from 'react';
import { animated, interpolate } from 'react-spring';

const HomePreview = ({ reportage, corporate, y, offset, active }) => {
  const smallerWidth =
    typeof window === 'undefined' || window.innerWidth > 576 ? 120 : 70;
  const activeWidth =
    typeof window === 'undefined'
      ? 88
      : 100 - (smallerWidth * 100) / window.innerWidth;

  const baseWidth = 100 - activeWidth;

  return (
    <animated.div
      className="home-preview"
      style={{
        transform: interpolate(
          [y.interpolate(y => y), offset.interpolate(offset => offset)],
          (y, offset) => `translate3d(0px, ${offset + y}px, 0px)`
        ),
      }}
    >
      <div
        style={{
          width: active === 'reportage' ? activeWidth + '%' : baseWidth + '%',
          position: 'relative',
        }}
      >
        <div
          className="home-preview-background-overlay-reportage"
          style={{
            opacity: active === 'reportage' ? 0.4 : 1,
          }}
        ></div>
        <img src={reportage} className="reportage-thumb"></img>
      </div>
      <div
        style={{
          width: active === 'corporate' ? activeWidth + '%' : baseWidth + '%',
          position: 'relative',
        }}
      >
        <div
          className="home-preview-background-overlay-corporate"
          style={{
            opacity: active === 'corporate' ? 0.4 : 1,
          }}
        ></div>
        <img src={corporate} className="corporate-thumb"></img>
      </div>
    </animated.div>
  );
};

export default HomePreview;
